<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Player White List</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="params.search"
        append-icon="mdi-magnify"
        :label="$t('input.search')"
        single-line
        hide-details
        clearable
        @input="fetchListStatement(params)"
        class="mr-5" />
      <v-btn class="ml-5" color="primary" @click="onCreateDialog" v-if="checkUserPermission('CREATE')">{{ $t('button.create') }}</v-btn>

      <dialog-delete
          :dialog-delete="dialogDelete"
          :darkmode="darkmode"
          v-on:closeDelete="closeDelete"
          v-on:deleteItemConfirm="deleteItemConfirm"
      /> 
    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  components: {DialogDelete},
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission'],
  data() {
    return {
      dialogDelete: false,
    };
    
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },

    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.dialogDelete = false;
    },
    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    ...mapActions("$_playerWhiteList", ["fetchListStatement", "deleteData", "fetchPopUp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
